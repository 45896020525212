import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withStyles } from '@mui/styles';
import { Icon } from '@iconify/react';
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Collapse,
	Box,
	Card,
	CardActionArea,
	CardMedia,
	Typography
} from '@mui/material';
import { ExpandLess, ExpandMore, AddCircle } from '@mui/icons-material';
import useAuth from '../../../../hooks/useAuth';
import { MFab } from '../../../../components/@material-extend';
import { getAgentList } from '../../../../redux/actions/chatbotActions';
import { ADMIN_ROLE, USER_ROLE, SUPERADMIN_ROLE } from '../../../../constants';

import styles from './styles';

function Sidebar({ classes, className, open, onToggleSidebar, theme }) {
	const dispatch = useDispatch();
	const agentList = useSelector(state => state.chatbot.agents);
	const isMobile = useSelector(state => state.layout.isMobile);

	const { pathname } = useLocation();

	const { user } = useAuth();

	const [isOpen, setIsOpen] = useState({});

	const SIDEBAR_LINKS = [
		{
			pathname: '/dashboard',
			name: 'Home',
			icon: 'teenyicons:home-solid',
			accessibleRole: [USER_ROLE, ADMIN_ROLE]
		},
		agentList?.data?.length > 1
			? {
					pathname: '',
					name: 'Agents',
					icon: 'teenyicons:chatbot-solid',
					accessibleRole: [ADMIN_ROLE, USER_ROLE],
					subLinks: agentList?.data?.map(agent => ({
						pathname: `/dashboard/chat/${agent.agent_id}`,
						name: agent.display_name,
						icon: 'ri:chat-1-line',
						accessibleRole: [ADMIN_ROLE, USER_ROLE]
					}))
			  }
			: agentList?.data?.length
			? {
					pathname: `/dashboard/chat/${agentList?.data?.[0]?.agent_id}`,
					name: agentList?.data?.[0]?.display_name,
					icon: 'teenyicons:chatbot-solid',
					accessibleRole: [USER_ROLE, ADMIN_ROLE]
			  }
			: {
					pathname: ``,
					name: 'Loading...',
					icon: 'line-md:loading-loop',
					accessibleRole: [USER_ROLE, ADMIN_ROLE]
			  },
		{
			pathname: '/dashboard/docs',
			name: 'File Manager',
			icon: 'teenyicons:folders-solid',
			accessibleRole: [ADMIN_ROLE]
		},
		{
			pathname: '/dashboard/agents',
			name: 'Agent Manager',
			icon: 'fluent:chat-settings-24-filled',
			accessibleRole: [ADMIN_ROLE]
		},
		/* 	{
			pathname: '/dashboard/faq',
			name: 'FAQs',
			MuiIcon: sidebarIcons.ContactSupport,
			accessibleRole: [ADMIN_ROLE]
		}, */
		{
			pathname: '/dashboard/users',
			name: 'Users',
			icon: 'teenyicons:users-solid',
			accessibleRole: [ADMIN_ROLE]
		},
		{
			pathname: '',
			name: 'Superadmin',
			icon: 'eos-icons:admin',
			accessibleRole: [SUPERADMIN_ROLE],
			subLinks: [
				{
					pathname: '/dashboard/superadmin/agent-manager',
					name: 'Agent Manager',
					icon: 'streamline:ai-settings-spark',
					accessibleRole: [SUPERADMIN_ROLE]
				},
				{
					pathname: '/dashboard/superadmin/feedback',
					name: 'Feedback',
					icon: 'ci:chat-check',
					accessibleRole: [SUPERADMIN_ROLE]
				},
				{
					pathname: '/dashboard/superadmin/testing',
					name: 'Testing',
					icon: 'ci:monitor',
					accessibleRole: [SUPERADMIN_ROLE]
				},
				{
					pathname: '/dashboard/superadmin/llm-manager',
					name: 'LLM Manager',
					icon: 'octicon:ai-model-24',
					accessibleRole: [SUPERADMIN_ROLE]
				}
				/* {
					pathname: '/dashboard/roles-and-permissions',
					name: 'Roles & Permissions',
					MuiIcon: sidebarIcons.Superadmin,
					accessibleRole: [SUPERADMIN_ROLE]
				} */
			]
		}
	];

	useEffect(() => {
		// Fetch agent list or any other necessary initializations here
		dispatch(getAgentList());
	}, []);

	const handleCollapseClick = name => {
		setIsOpen(prevState => ({
			...prevState,
			[name]: !prevState[name]
		}));
	};

	const rootClassName = classNames(classes.root, className);

	return (
		<>
			<Scrollbars style={{ width: '100%' }}>
				<nav style={{ width: '100%' }} className={rootClassName}>
					<Box onClick={() => window.open('/', '_self')}>
						<img
							alt="Responsum Logo"
							className={classes.logoImage}
							src="/images/logos/responsum.png"
						/>
					</Box>

					<List component="div" disablePadding>
						{SIDEBAR_LINKS.map(link => {
							const isSuperadminRoute = link.accessibleRole.includes(SUPERADMIN_ROLE);
							if (
								(!user.isSuperadmin && isSuperadminRoute) ||
								(!isSuperadminRoute && !link.accessibleRole.includes(user.role))
							) {
								return null;
							}

							if (link.subLinks) {
								const openCollapse = link.subLinks.some(el =>
									pathname.includes(el?.pathname)
								);
								return (
									<React.Fragment key={link.name}>
										<ListItemButton
											key={link.name}
											sx={{ height: 50 }}
											onClick={() => handleCollapseClick(link.name)}
											className={
												openCollapse ? classes.activeDropdownLink : ''
											}
											selected={openCollapse}
										>
											<ListItemIcon className={classes.listItemIcon}>
												<Icon fontSize={18} icon={link.icon} />
											</ListItemIcon>
											{open && (
												<ListItemText
													sx={{
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap'
													}}
													disableTypography
													primary={link.name}
												/>
											)}
											{isOpen[link.name] || openCollapse ? (
												<ExpandLess
													fontSize="small"
													className={classes.listItemArrow}
												/>
											) : (
												<ExpandMore
													className={classes.listItemArrow}
													fontSize="small"
												/>
											)}
										</ListItemButton>
										<Collapse
											in={isOpen[link.name] || openCollapse}
											timeout="auto"
											unmountOnExit
											className={classes.sidebarCollapse}
										>
											{link.subLinks.map(subLink => (
												<NavLink
													key={subLink.name}
													to={subLink?.pathname}
													className={classes.navSubLink}
													onClick={
														isMobile ? () => onToggleSidebar() : null
													}
												>
													<ListItemButton
														sx={{ height: 50 }}
														className={
															window.location?.pathname.includes(
																subLink?.pathname
															)
																? classes.activeSubListItem
																: ''
														}
													>
														<ListItemIcon
															className={classes.listItemIcon}
														>
															<Icon
																fontSize={19}
																icon={subLink.icon}
															/>
														</ListItemIcon>
														{open && (
															<ListItemText
																disableTypography
																primary={subLink.name}
																sx={{
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	whiteSpace: 'nowrap'
																}}
															/>
														)}
													</ListItemButton>
												</NavLink>
											))}
										</Collapse>
									</React.Fragment>
								);
							}

							return (
								<NavLink
									key={link.name}
									to={link?.pathname}
									className={classes.navLink}
									onClick={isMobile ? () => onToggleSidebar() : null}
								>
									<ListItemButton
										sx={{ height: 50 }}
										className={
											pathname === link?.pathname
												? classes.activeListItem
												: ''
										}
										selected={pathname === link?.pathname}
									>
										<ListItemIcon className={classes.listItemIcon}>
											<Icon fontSize={19} icon={link.icon} />
										</ListItemIcon>
										{open && (
											<ListItemText
												sx={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap'
												}}
												disableTypography
												primary={link.name}
											/>
										)}
									</ListItemButton>
								</NavLink>
							);
						})}
					</List>
				</nav>
			</Scrollbars>
			<MFab
				color="default"
				className={`${classes.menuToggleButton} ${!open && classes.menuButtonOpen}`}
				onClick={onToggleSidebar}
				variant="text"
				sx={{
					backgroundColor: theme.palette.background.dark,
					'&:hover': {
						backgroundColor: theme.palette.background.default
					},
					borderRadius: '10px'
				}}
			>
				{open ? (
					<Icon
						color={theme.palette.text.secondary}
						fontSize={22}
						icon="tabler:layout-sidebar-left-collapse"
					/>
				) : (
					<Icon
						color={theme.palette.text.secondary}
						fontSize={22}
						icon="tabler:layout-sidebar-left-expand"
					/>
				)}
			</MFab>
			{open && (
				<Card
					variant="outlined"
					sx={{
						position: 'absolute',
						bottom: 15,
						left: '50%',
						transform: 'translateX(-50%)',
						width: '120px',
						display: 'none'
					}}
				>
					<CardActionArea
						sx={{
							p: 1.5,
							display: 'flex',
							flexDirection: 'column'
						}}
						target="_blank"
						href="https://teams.microsoft.com/l/app/A0279PG7EC9"
					>
						<CardMedia
							component="img"
							alt="Teams Logo"
							image="/images/connectors/teams-128.png"
							sx={{ width: 45 }}
						/>
						<AddCircle
							sx={{
								position: 'absolute',
								right: '5px',
								top: '5px',
								opacity: 0.3
							}}
						/>

						<Typography variant="body2" sx={{ opacity: 0.7 }}>
							Add to Teams
						</Typography>
					</CardActionArea>
				</Card>
			)}
		</>
	);
}

export default withStyles(styles, { withTheme: true })(Sidebar);
